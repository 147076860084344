@import "variables";
@import "fonts";
@import "default";
@import "buttons";
@import "layout";
@import "navbar";
@import "footer";
@import "page-header";
@import "home";
@import "projects";
@import "project";
@import "services";
@import "contact";

.client-logo{
    // background-color: #fafafa;
    border-radius: 0.75rem;
    margin: 0 0.5rem;
    transition: transform .3s ease;
    width: 250px;
    height: 130px;
    // border: 1px solid #ccc;
    object-fit: contain;
    margin-bottom: 10px;
}
@media(min-width: 1000px){
    li.nav-item:nth-last-child(1) {
        border-left: 1px solid #000;
    }
}
