#intro-wrapper{
    overflow: hidden;
    position: relative;
}
.intro{
    z-index: 9999;
    width: 100%;
    position: relative;
    // background: rgba($color: #000000, $alpha: 0.5);
    background: linear-gradient(to bottom, RGBA(56,50,50,0.84) 0%,RGBA(100,7,7,0.69) 100%);
    height: 90vh;
    display: flex;
    align-items: center;
    .btn-outline-dark{
        z-index: 99;
    }
  .background-image{
    background-image: url(../images/home-hero.webp);
    z-index: -2;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;

  }
    .overlay{
        padding: 20px;
        width: 100%;
        height: 100vh;
        position: relative;
        color: #000;
        display: flex;
        align-items: center;
     
    }
    .photo{
       display: flex;
        #myphoto{
            background-size: cover;
            width: 250px;
            aspect-ratio: 1/1;
            border: 1px solid #000;
            border-radius: 100%;
            @media(max-width: 768px){
                margin: auto;
            }
           
        }
    
    }
    h1{
   
        padding-top: 20px;
        padding-bottom: 20px;
        text-align: left;
        color: #fff;
        font-weight: bold;
        text-transform: uppercase;
        max-width: 900px;
        font-size: 67px;
        @media(max-width:768px) {
            
            font-size: 42px;
        }
    }
    .subtitle{
        font-size: 24px;
        color: #626262;
        @media(max-width:768px) {
            
            font-size: 20px;
        }

    }
    .name{
        font-size: 60px;
        @media(max-width: 768px){
            font-size: 50px;
        }
    }
    .designation{
        color: #666666;

    }
    
    .social-media-icons{
        display: flex;

        a{
            text-decoration: none;
            color: #000;
            margin-right: 20px;
            i{
                color: #000;
                font-size: 42px;
                padding: 20px;
                border: 1px solid #000;
                width: 80px;
                height: 80px;
                background: #fff;
            }
        }
       
    }
    
}
  
.section-marquee-text{
    .text-item{
        font-size: 70px;
        padding: 0 30px;
        font-weight: 500;
        line-height: normal;
        color: map-get($theme-color-primary, "main");;
        text-transform: uppercase;
        @media(max-width: 768px){
            font-size: 40px;
        }
    }
}
#tsparticles {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
 
}
.parallax-home{
    &::before{
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        content: '';
        background: rgba($color: #000000, $alpha: 0.6);
    }
    .inner-text{
        padding: 20px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        color: #fff;
        line-height: normal;
        text-transform: uppercase;
        width: 100%;
        .main-text{
            font-size: 60px;
            color: #fff;
            font-weight: bold;
            @media(max-width: 600px){
                font-size: 40px;
            }
        }

    }
}
.section-testimonials{
    .avatar{
        img{
            width: 250px;
            height: 250px;
            object-fit: cover;
            border-radius: 100%;
            border: 1px solid #ccc;
        }
    }
    .card{
        border: none;
    }
}

.hero-home-page{
    margin: auto;
    .left-right-wrapper{
        display: grid;
        grid-template-columns: 1fr 1fr;
        @media(max-width: 768px){
            grid-template-columns: 1fr;
        }
        .title{
            font-size: 60px;
            font-weight: bold;
            @media(max-width: 768px){
                font-size: 40px;
            }
            @media(min-width: 768px)  and (max-width:1368px){
                font-size: 40px;
            }
        }
        p{
            font-size: 27px;
            @media(max-width: 768px){
                font-size: 20px;
            }
            
        }
        .left-col{
            background: #fff;
            z-index: 1;
            padding: 90px 40px 90px 0;
            margin: 120px 0;
            height: fit-content;
            @media(max-width: 768px){
                margin: 0;
                padding: 40px 0;
            }
        }
        .right-col{
            margin-left: -100px;
            margin-top: 40px;
            img{
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
            @media(max-width: 768px){
                margin: 0;
            }
          
        }
      
    }
}