*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    // font-family: 'Arimo', sans-serif;
    // font-family: "Nunito Sans", sans-serif;
    font-family: 'Poppins', sans-serif;
    color: #000;
    word-break: break-word;
    word-wrap: break-word;

}
img{
    max-width: 100%;
}
a{
    text-decoration: none;
    
}

.section-title{
    margin-bottom: 40px;
    text-transform: uppercase;
    color: map-get($theme-color-primary, "main");
    font-weight: normal;
}

.small-title{
    font-size: 14px;
    margin-bottom: 20px;
    position: relative;
    // padding-left: 30px;
    display: flex;
    align-items: center;
    &::before{
        content: '</>';
        margin-right: 10px;
        left: 0;
        color: #000;
        min-width: max-content;
        word-wrap: unset;
        word-break: keep-all;
      
    }
    &.text-white{
        color: #fff;
        &::before{
            color: #fff;
        }
    }
}

h1,h2,h3,h4,h5,h6{
    color: map-get($theme-color-primary, "main");
}

p{
    color: map-get($text-color, "default");
}