.contact-form{
    box-shadow: 0 0 10px #ccc;
    padding: 40px;
    label{
        display: block;
        margin-bottom: 10px;
    }
    input, textarea{
        width: 100%;
        padding: 10px;
    }
    .formcarry-block{
        margin-bottom: 40px;
    }

}