.services-items-wrapper{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 40px;
    word-wrap: break-word;
    @media(max-width: 1024px){
        grid-template-columns: 1fr 1fr;
    }
    @media(max-width: 768px){
        grid-template-columns: 1fr;

    }
    .item{
        position: relative;
        // aspect-ratio: 1 / 1;
        text-decoration: none;
        .description-box{
            position: relative;
            bottom: 0;
            // background: rgba($color: #000, $alpha: 0.8);
            width: 100%;
            color: #fff;
            font-weight: 400;
            padding: 10px 0;
            // border-top: 1px solid #ffffff7d;
            .title{
                color: #000;
                font-size: 22px;
                text-transform: uppercase;
                text-decoration: none;
                font-weight: 400;
            }
            .type{
                text-transform: uppercase;
                font-size: 12px;
                letter-spacing: 1px;
            }
        }
        // &:hover{
        //     .title{
        //         color: #000;
        //     }
        //     .description-box{
        //         background: rgba($color: #fff, $alpha: 0.1);
        //         color: #000000;

        //     }
        //     &:before{
        //         position: absolute;
        //         left: 0;
        //         right: 0;
        //         top: 0;
        //         bottom: 0;
        //         position: absolute;
        //         content: '';
        //         background: linear-gradient(rgba(#000, 0.1),  rgba(#fff, 0.7), rgba(#fff, 1));
        //     }
           
        // }
        .image-wrapper{
            border: 1px solid #000;
            overflow: hidden;
            img{
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                aspect-ratio: 1 / 1;
                object-fit: contain;
                width: 100%;
                height: fit-content;
                &:hover{
                    scale: 1.1;
                    transition-duration: 500ms;
                    transform-origin: 50% 50% 0px;
                }
             
     
             }

        }
        
    }
}