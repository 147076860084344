.section.page-header{
    .title-desc-wrapper{
        display: grid;
        grid-template-columns: 1fr 1fr;
        .page-title{
            text-transform: uppercase;
            padding-right: 40px;
            font-weight: bold;
        }
        .description{
            border-left: 1px solid #000;
            padding-left: 50px;
            font-size: 27px;
        }
        @media(max-width: 768px){
            grid-template-columns: 1fr;
            grid-gap: 20px;
        }

    }
    
}