.container-full-width{
    padding: 0 40px;
    margin: auto;
    max-width: 2000px !important;
    @media(max-width: 768px){
        padding: 0 15px;
    }
}
.section{
    padding: 90px 0;
    @media(max-width: 768px){
        padding: 40px 0;
    }
}
.border{
    border: 1px solid map-get($theme-color-primary, "main");
}
body .border-top-bottom{
    border-top: 1px solid map-get($theme-color-primary, "main") !important;
    border-bottom: 1px solid map-get($theme-color-primary, "main") !important;
}
body .border-top{
    border-top: 1px solid map-get($theme-color-primary, "main") !important;
}
body .border-bottom{
    border-bottom: 1px solid map-get($theme-color-primary, "main") !important;
}